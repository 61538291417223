<template>
<!--  Long Receipt icon from material design iconset-->
<v-icon right>
  {{svgPath}}
</v-icon>

</template>

<script>

  export default {
     name: "TutorialIcon",
     data: ()=>({
       svgPath: "M6,2C4.9,2 4,2.9 4,4V20C4,21.1 4.9,22 6,22H10V20.1L20,10.1V8L14,2H6M13,3.5L18.5,9H13V3.5M20.1,13C20,13 19.8,13.1 19.7,13.2L18.7,14.2L20.8,16.3L21.8,15.3C22,15.1 22,14.7 21.8,14.5L20.5,13.2C20.4,13.1 20.3,13 20.1,13M18.1,14.8L12,20.9V23H14.1L20.2,16.9L18.1,14.8Z"
     // svgPath:"M17.8,20C17.4,21.2 16.3,22 15,22H5C3.3,22 2,20.7 2,19V18H5L14.2,18C14.6,19.2 15.7,20 17,20H17.8M19,2C20.7,2 22,3.3 22,5V6H20V5C20,4.4 19.6,4 19,4C18.4,4 18,4.4 18,5V18H17C16.4,18 16,17.6 16,17V16H5V5C5,3.3 6.3,2 8,2H19M8,6V8H15V6H8M8,10V12H14V10H8Z"
     // svgPath:"M15,20C15.55,20 16,19.55 16,19V4H8C7.45,4 7,4.45 7,5V16H5V5C5,3.34 6.34,2 8,2H19C20.66,2 22,3.34 22,5V6H20V5C20,4.45 19.55,4 19,4C18.45,4 18,4.45 18,5V9L18,19C18,20.66 16.66,22 15,22H5C3.34,22 2,20.66 2,19V18H13C13,19.1 13.9,20 15,20M9,6H14V8H9V6M9,10H14V12H9V10M9,14H14V16H9V14Z"
     })
  }
</script>

<style scoped>

</style>