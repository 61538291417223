<template>

  <v-row>
    <v-col cols="10" offset="1" align="center">
      <h1 class="text-h4 mb-6 " >Terms of Service</h1>
      <p class=" text-justify">
        Copyright 2020 MCM-Mike <a>(https://github.com/MCM-Mike/grinnode.live)</a>
      </p>
      <p  class="text-justify">
        Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

        The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
      </p >

      <p  class="text-justify">
        THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
      </p>
      <p  class="text-justify">
        If we determine that your use of the API is used in a malicious way to harm Grinnode.live infrastucture, we reserve the right to deactivate any access, block your IP address(es), or otherwise block your use of the API.
      </p>


    </v-col>
  </v-row>



</template>

<script>
  export default {
    name: "ToS"
  }
</script>

<style scoped>

</style>
