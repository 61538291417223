<template>
<!--  Contact page icon from material icon set-->
    <v-icon right>
      {{svgPath}}
    </v-icon>

</template>

<script>

export default {
  name: "ContactIcon",
  data : ()=>({
    svgPath : "M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z M12,10c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2s-2-0.9-2-2 C10,10.9,10.9,10,12,10z M16,18H8v-0.57c0-0.81,0.48-1.53,1.22-1.85C10.07,15.21,11.01,15,12,15c0.99,0,1.93,0.21,2.78,0.58 C15.52,15.9,16,16.62,16,17.43V18z"
  })
}
</script>

<style scoped>

</style>